import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAll() {
  return getUrl("/convenience/all");
}

export function addConvenience(data: any) {
  return postUrl("/convenience/add", data);
}

export function updateConvenience(id: string, data: any) {
  return postUrl("/convenience/update?id=" + id, data);
}

export function removeConvenience(id: string) {
  return deleteUrl("/convenience/remove?id=" + id);
}
