import React from "react";
import TableDataAutoList from "../../../../components/array";
import "moment/locale/fr";
import { usePoliciesStore } from "../../../../configs/stores/policies";
// import UserActions from "./actions";

const PolicyNoTable = () => {
  const { policies } = usePoliciesStore();
  const columns = [
    {
      label: "Compagnie",
      slug: "company",
      maxWidth: "350px",
    },
    {
      label: "Décisions",
      slug: "decision",
      maxWidth: "200px",
    },
    {
      label: "Fichiers (KYC)",
      slug: "files",
      maxWidth: "200px",
    },
    // {
    //   label: "Status",
    //   slug: "state",
    //   maxWidth: "80px",
    // },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        rows={policies
          .filter((e) => !e.contract_status.active)
          .map(function (policy) {
            return {
              policy,
              company: policy.contract_company.company_name,
              decision: `Roy:${policy.contract_decision.royality}; Charge:${policy.contract_decision.charge}`,
              files: policy.contract_kyc.length+"/3"
            };
          })}
      />
    </div>
  );
};

export default PolicyNoTable;
