import React from "react";

export function useOneEffect(
  funct = (ref: any = null) => {},
  dependencies: Array<any> = []
) {
  const flag = React.useRef(false);
  React.useEffect(() => {
    if (flag.current === false) {
      funct(flag);
    }
    return () => {
      flag.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funct, ...dependencies]);
}
