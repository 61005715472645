import React from "react";
import TableDataAutoList from "../../../../components/array";
import "moment/locale/fr";
import { useUsersStore } from "../../../../configs/stores/users";
import UserActions from "./actions";

const UsersTable = () => {
  const { users } = useUsersStore();
  const columns = [
    {
      label: "Utilisateur",
      slug: "name",
      maxWidth: "200px",
    },
    {
      label: "Téléphone",
      slug: "phone",
      maxWidth: "200px",
    },
    {
      label: "Email",
      slug: "email",
      maxWidth: "200px",
    },
    {
      label: "Etat",
      slug: "state",
      maxWidth: "80px",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        actions={UserActions}
        rows={users.map(function (user) {
          return {
            user,
            name: user.user_fullname,
            phone: user?.user_phone ?? "Non definie",
            email: user?.user_email ?? "Non definie",
            state: user?.user_status.active ? "Actif" : "Désactivé",
          };
        })}
      />
    </div>
  );
};

export default UsersTable;
