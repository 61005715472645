import React from "react";
import RefreshButton from "../../../../components/buttons/refeshButton";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import AdminsTable from "./table";
import { useAdminsStore } from "../../../../configs/stores/admins";

const AdminsPage = () => {
  const isLoading = useAdminsStore((e) => e.isLoading);
  const getAll = useAdminsStore((e) => e.getAll);
  function refresh() {
    getAll();
  }
  useOneEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        refresh();
      }, 3000);
    }
  }, []);
  return (
    
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-10 pt-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">Liste des Administrateurs</span>
          <div className="flex flex-row gap-4">
            <RefreshButton refresh={refresh} isLoading={isLoading} />
          </div>
        </div>
        <AdminsTable />
      </div>
    </React.Fragment>
  );
};

export default AdminsPage;
