import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { CompanyModel } from "../../models/models";
import { ToastWarnNotifier } from "../../constants";
import { getAllCompany, removeCompany, updateCompany } from "./functions/company";

const LOCAL_DATASTORE_NAME = "lesvoyageursadmin-companies";

type ContextProps = {
  isLoading: boolean;
  companies: Array<CompanyModel>;
  max: number;
  getAll: (max?: string) => Promise<any>;
  getMore: (id: string) => Promise<any>;
  updateCompany: (id: string, companyId: string, update: any) => Promise<any>;
  removeCompany: (id: string, companyId: string) => Promise<any>;
};

export const useCompanyStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      companies: [],
      max: 10,
      getAll: async (max) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const state = get();
        const res = await getAllCompany(max ?? state.max.toString());
        const data = (res?.data ?? []) as Array<CompanyModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.companies = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      getMore: async (id: string) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const state = get();
        let max_updated = state.max + 10;
        if (state.max > state.companies.length) {
          max_updated = state.max;
        }
        set(
          produce((state: ContextProps) => {
            state.max = max_updated;
          })
        );
        const res = await getAllCompany(max_updated.toString());
        const data = (res?.data ?? []) as Array<CompanyModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.companies = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      updateCompany: async (id: string, companyId, update: any) => {
        const state = get();
        const res = await updateCompany(id, update);
        if (res.success) {
          await state.getAll(state.max.toString());
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      removeCompany: async (id: string, companyId) => {
        const state = get();
        const res = await removeCompany(id);
        if (res.success) {
          await state.getAll(state.max.toString());
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
