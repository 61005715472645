import React from "react";

type Props = {
  error: { status: boolean; msg: string };
  onTextChange?: (value: string) => void;
};

const LoginEmailInput = ({ error, onTextChange = (str) => {} }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <div className="flex flex-col relative gap-1">
      <input
        ref={inputRef}
        type="text"
        name="logininput"
        placeholder={"Login"}
        maxLength={30}
        onChange={(e) => onTextChange(e.target.value ?? "")}
        className={`flex transition-all duration-150 flex-1 rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary ${
          error.status
            ? "caret-red-500 border-red-500 outline-none placeholder:text-red-500"
            : "border-slate-300 outline-none focus:border-primary"
        } font-medium text-[19px] py-3 bg-slate-100`}
      />
      {error.msg.length !== 0 && (
        <span className="flex select-none pl-[6px] text-[14px] text-red-500">{error.msg}</span>
      )}
    </div>
  );
};

export default LoginEmailInput;
