import React from "react";
import TableDataAutoList from "../../../../components/array";
import "moment/locale/fr";
import UserActions from "./actions";
import { useVouchersStore } from "../../../../configs/stores/voucher";

const VouchersTable = () => {
  const { vouchers } = useVouchersStore();
  const columns = [
    {
      label: "Code",
      slug: "code",
    },
    {
      label: "Type de reduction",
      slug: "type",
    },
    {
      label: "Taux de reduction",
      slug: "rate",
    },
    {
      label: "Etat",
      slug: "state",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        actions={UserActions}
        rows={vouchers.map(function (voucher) {
          return {
            voucher,
            code: voucher.voucher_code,
            type: voucher.voucher_type === "percent" ? "Pourcentage" : "Fixe",
            rate: voucher.voucher_rate,
            state: voucher.voucher_active,
          };
        })}
      />
    </div>
  );
};

export default VouchersTable;
