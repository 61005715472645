import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllVoucher(max: string = "10") {
  return getUrl(`/voucher/all?max=${max}`);
}

export function updateVoucher(id: string = "", data: any = {}) {
  return postUrl("/voucher/update?id=" + id, data);
}

export function removeVoucher(id: string = "") {
  return deleteUrl("/voucher/remove?id=" + id);
}
