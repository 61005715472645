import React from "react";
import { NavLink } from "react-router-dom";
import { primaryColor } from "../../constants";

const SidebarItem = ({ item }) => {
  return (
    <NavLink
      to={item.path ?? ""}
      end={item.path === ""}
      className={({ isActive }) => {
        const def =
          "flex w-full gap-3 transition-all duration-150 py-[12px] px-4 pl-[30px] cursor-pointer select-none items-center ";
        if (isActive) {
          return def + "text-black bg-white duration-0";
        }
        return def + "hover:bg-slate-200/50 duration-100";
      }}
    >
      {({ isActive }) => (
        <>
          {!!item.icon && (
            <div className="flex w-[30px]">
              {item.icon({
                color: isActive ? primaryColor : "white",
              })}
            </div>
          )}
          <span
            className={`flex text-[16px] mt-px font-medium ${
              isActive ? " text-primary" : "text-white"
            }`}
          >
            {item.name}
          </span>
        </>
      )}
    </NavLink>
  );
};

export default SidebarItem;
