import React from "react";
import { DashboardRoutes } from "../routes/dashboard";
import voyageurLogo from "../../assets/voyageurLogo.svg";
import SidebarItem from "./item";

const SideBar = () => {
  return (
    <div className="flex w-[230px] flex-col bg-primary">
      <div className="flex flex-row gap-2 h-[60px]">
        <a href="/" className="flex m-auto">
          <img className="w-[166px]" alt="" src={voyageurLogo} />
        </a>
      </div>
      <div className="flex flex-col pb-4 h-full max-h-[calc(100vh-88px)] overflow-y-auto scrollbar-thumb-white/50 scrollbar-thumb-rounded scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-white/70 active:scrollbar-thumb-white/70">
        {DashboardRoutes.filter(
          (e) => e.show_in_sidebar && !e.on_sidebar_bottom
        ).map((item, index) => {
          return <SidebarItem key={index} item={item} />;
        })}
        <div className="flex mt-auto">
          {DashboardRoutes.filter(
            (e) => e.show_in_sidebar && e.on_sidebar_bottom
          ).map((item, index) => {
            return <SidebarItem key={index} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
