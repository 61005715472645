import React from "react";
// import TicketUpdate from "../../../../components/modals/models/station-update";
// import ConfirmActionModal from "../../../../components/modals/models/confirmAction";
import { useTicketStore } from "../../../../configs/stores/ticket";

type TicketActionsProps = {
  row: any;
};

const TicketActions = ({ row }: TicketActionsProps) => {
  const { removeTicket } = useTicketStore();
  const [isOpenUpdate, setisOpenUpdate] = React.useState(false);
  function closeUpdate() {
    setisOpenUpdate(false);
  }
  const [isOpenDelete, setisOpenDelete] = React.useState(false);
  function closeDelete() {
    setisOpenDelete(false);
  }
  // async function Delete() {
  //   await removeTicket(
  //     row.station.station_id,
  //     row.station.station_company?.company_id ?? row.station.station_company
  //   );
  // }
  return (
    <React.Fragment>
      <div className="flex flex-col space-y-1">
        <TicketAction
          title={"Modifier"}
          onClick={() => setisOpenUpdate(true)}
        />
        {/* <TicketAction
          title={"Supprimer"}
          onClick={() => setisOpenDelete(true)}
        /> */}
      </div>
      {/* <TicketUpdate
        station={row.station}
        open={isOpenUpdate}
        handleClose={closeUpdate}
      />
      <ConfirmActionModal
        open={isOpenDelete}
        message="Voulez-vous vraiment supprimer ?"
        handleClose={closeDelete}
        confirm={Delete}
      /> */}
    </React.Fragment>
  );
};

type TicketActionProps = {
  title: string;
  onClick: () => void;
};

const TicketAction = ({ title, onClick }: TicketActionProps) => {
  return (
    <button
      onClick={onClick}
      className="flex pl-3 pr-1 py-2 hover:bg-slate-200/40 active:text-primary"
    >
      <span className="flex font-medium">{title}</span>
    </button>
  );
};

export default TicketActions;
