import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/sidebar";
import { primaryColor } from "../../constants";
import HelpIcon from "../../components/icons/helpIcon";
import AlarmIcon from "../../components/icons/alarmIcon";
import PersonIcon from "../../components/icons/personIcon";
import { useAuthStore } from "../../configs/stores/auth";
import ConfirmActionModal from "../../components/modals/models/confirmAction";
import { usePopUpHook } from "../../hooks/popuphook";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

const Dashboard = () => {
  const { isAuthenticated } = useAuthStore();
  const { refInput, refPopUp, open, switchMenu, closeM } = usePopUpHook<
    HTMLButtonElement,
    HTMLDivElement
  >({
    onClose() {
      closeM();
    },
  });
  function onClick() {
    switchMenu();
  }
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col ">
        <div className="flex flex-1 flex-row h-full">
          <SideBar />
          <div className="flex flex-1 flex-col gap-3 p-7 pt-4 py-6 pb-0 pr-2">
            <div className="flex flex-row gap-6 h-[60px] items-center pr-10">
              <input
                className="flex ml-auto my-auto bg-white px-5 rounded-md py-2"
                placeholder="Rechercher"
              />
              <HelpIcon color={primaryColor} />
              <AlarmIcon color={primaryColor} />
              <div className="flex p-[10px] cursor-pointer aspect-square bg-white rounded-full">
                <PersonIcon
                  ref={refInput}
                  onClick={onClick}
                  color={primaryColor}
                />
                <div
                  ref={refPopUp}
                  className="absolute select-none z-[700] flex-col top-[90px] phone:top-[60px] right-8 phone:right-0 w-[200px] overflow-y-auto rounded-lg bg-white border overflow-hidden shadow-md shadow-black/30 text-[16px]"
                  style={{
                    visibility: open ? "visible" : "hidden",
                  }}
                >
                  {isAuthenticated && <UserConnectedProfileMenus />}
                  {!isAuthenticated && <NotConnectedMenus />}
                </div>
              </div>
            </div>
            <div className="flex flex-1 max-h-[calc(100vh-128px)] pb-2 pr-10 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/40 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type ButtonProfileProps = {
  title: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

function ButtonProfile({
  title,
  href,
  onClick = (e) => {},
}: ButtonProfileProps) {
  const navigate = useNaviLoadBoth;
  function click(e: any) {
    !!onClick && onClick(e);
    !!href && navigate(href);
  }
  return (
    <div
      onClick={click}
      className="flex cursor-pointer px-3 py-1 h-[40px] items-center hover:bg-black/15 active:bg-black/10 rounded-[10px]"
    >
      <span className="flex">{title}</span>
    </div>
  );
}

function UserConnectedProfileMenus() {
  return (
    <div className="flex flex-col p-1">
      <LogOutAccount />
    </div>
  );
}

const LogOutAccount = () => {
  const logout = useAuthStore((e) => e.logout);
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    return await logout();
  }
  return (
    <React.Fragment>
      <ButtonProfile title="Deconnexion" onClick={() => setisOpen(true)} />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment vous déconnecter ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

function NotConnectedMenus() {
  return (
    <div className="flex flex-col p-1">
      <ButtonProfile title="Connexion" href="login" />
      <ButtonProfile title="Créer un compte" href="signup" />
    </div>
  );
}

export default Dashboard;
