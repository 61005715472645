import React from "react";

const PersonIcon = ({ color = "", ...args }) => {
  return (
    <>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...args}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.84 16.1934C15.84 19.4894 11.32 19.8704 7.921 19.8704L7.67776 19.8702C5.5122 19.8649 0 19.7282 0 16.1734C0 12.9447 4.33835 12.5132 7.71148 12.4969L8.16423 12.4966C10.3296 12.5019 15.84 12.6386 15.84 16.1934ZM7.921 13.9964C3.66 13.9964 1.5 14.7284 1.5 16.1734C1.5 17.6314 3.66 18.3704 7.921 18.3704C12.181 18.3704 14.34 17.6384 14.34 16.1934C14.34 14.7354 12.181 13.9964 7.921 13.9964ZM7.921 0C10.849 0 13.23 2.382 13.23 5.31C13.23 8.238 10.849 10.619 7.921 10.619H7.889C4.967 10.61 2.6 8.227 2.60997 5.307C2.60997 2.382 4.992 0 7.921 0ZM7.921 1.428C5.78 1.428 4.03798 3.169 4.03798 5.31C4.031 7.444 5.76 9.184 7.892 9.192L7.921 9.906V9.192C10.061 9.192 11.802 7.45 11.802 5.31C11.802 3.169 10.061 1.428 7.921 1.428Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default PersonIcon;
