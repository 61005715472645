import CompaniesPage from "../../pages/dashboard/pages/companies";
import DashboardHome from "../../pages/dashboard/pages/home";
import AdminsPage from "../../pages/dashboard/pages/admins";
import TicketsPage from "../../pages/dashboard/pages/tickets";
import CompanyIcon from "../icons/companyIcon";
import HeadShape from "../icons/headShape";
import ParamtreIcon from "../icons/parametreIcon";
import TicketIcon from "../icons/ticketIcon";
import UserIcon from "../icons/userIcon";
import VoucherIcon from "../icons/voucherIcon";
import ConvenienceIcon from "../svgJsx/convenience";
import DashboardIcon from "../svgJsx/dashboard";
import PolicyIcon from "../svgJsx/policy";
import { RouteModel } from "./model";
import UsersPage from "../../pages/dashboard/pages/users";
import PoliciesPage from "../../pages/dashboard/pages/policies";
import VouchersPage from "../../pages/dashboard/pages/voucher";
import ConveniencesPage from "../../pages/dashboard/pages/conveniences";

export const DashboardRoutes: Array<RouteModel> = [
  {
    path: "",
    name: "Dashboard",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: DashboardIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <DashboardHome />,
      },
    ],
  },
  {
    path: "tickets",
    name: "Tickets",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: TicketIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <TicketsPage />,
      },
    ],
  },
  {
    path: "company",
    name: "Compagnies",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: CompanyIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <CompaniesPage />,
      },
    ],
  },
  {
    path: "users",
    name: "Utilisateurs",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: UserIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <UsersPage/>,
      },
    ],
  },
  {
    path: "policies",
    name: "Legal",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: PolicyIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <PoliciesPage />,
      },
    ],
  },
  {
    path: "conveniences",
    name: "Comodités",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: ConvenienceIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <ConveniencesPage />,
      },
    ],
  },
  {
    path: "voucher",
    name: "Code réduction",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: VoucherIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <VouchersPage />,
      },
    ],
  },
  {
    path: "admins",
    name: "Administrateurs",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: HeadShape,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <AdminsPage />,
      },
    ],
  },
  {
    path: "settings",
    name: "Parametres",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: ParamtreIcon,
    on_sidebar_bottom:true,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <DashboardHome />,
      },
    ],
  }
];
