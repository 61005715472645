import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { AdminModel } from "../../models/models";
import {
  getAllAdmin,
  newAdmin,
  removeAdmin,
  updateAdmin,
  updateAdminPassword,
} from "./functions/admins";
import { ToastWarnNotifier } from "../../constants";

export const AdminRoles = [
  {
    label: "Super Administrateur",
    value: "super-administrator",
    sub: ["*"],
    description: ``,
  },
];

export function getRoleLabel(value: string) {
  return AdminRoles.find((e) => e.value === value)?.label;
}
export function getRoleSub(value: string) {
  return AdminRoles.find((e) => e.value === value)?.sub;
}

const LOCAL_DATASTORE_NAME = "lesvoyageursadmin-admins";

type ContextProps = {
  isLoading: boolean;
  admins: Array<AdminModel>;
  getAll: () => Promise<any>;
  newAdmin: (data: any) => Promise<any>;
  updateAdmin: (id: string, update: any) => Promise<any>;
  updateAdminPassword: (
    id: string,
    update: any
  ) => Promise<any>;
  removeAdmin: (id: string) => Promise<any>;
};

export const useAdminsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      admins: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllAdmin();
        const data = (res?.data ?? []) as Array<AdminModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.admins = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      newAdmin: async (data) => {
        const state = get();
        const res = await newAdmin(data);
        if (res.success) {
          await state.getAll();
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateAdmin: async (id: string, update: any) => {
        const state = get();
        const res = await updateAdmin(id, update);
        if (res.success) {
          await state.getAll();
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateAdminPassword: async (id: string, update: any) => {
        const state = get();
        const res = await updateAdminPassword(id, update);
        if (res.success) {
          await state.getAll();
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      removeAdmin: async (id: string) => {
        const state = get();
        const res = await removeAdmin(id);
        if (res.success) {
          await state.getAll();
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
