import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useConveniencesStore } from "../../../../configs/stores/conveniences";
import RefreshButton from "../../../../components/buttons/refeshButton";
import ConvenienceAddModal from "../../../../components/modals/models/convenienve-add";
import React from "react";
import ConvenienceList from "./list";

const ConveniencesPage = () => {
  const [isOpen, setisOpen] = React.useState(false);
  const isLoading = useConveniencesStore((e) => e.isLoading);
  const getAll = useConveniencesStore((e) => e.getAll);
  function refresh() {
    getAll();
  }
  function openModal() {
    setisOpen(true);
  }
  function closeModal() {
    setisOpen(false);
  }
  useOneEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        refresh();
      }, 3000);
    }
  }, []);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-10">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">
            Liste des Comodités
          </span>
          <div className="flex flex-row gap-4">
            <RefreshButton refresh={refresh} isLoading={isLoading} />
            <button
              onClick={openModal}
              className="flex px-4 py-[10.5px] gap-2 items-center flex-row rounded-md bg-primary text-white hover:bg-hover active:bg-active"
            >
              <span className="flex text-[16px]">Ajouter des Comodités</span>
            </button>
          </div>
        </div>
        <ConvenienceList />
      </div>
      <ConvenienceAddModal handleClose={closeModal} open={isOpen} />
    </React.Fragment>
  );
};

export default ConveniencesPage;
