import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllUser(max: string = "10") {
  return getUrl(`/user/all?max=${max}`);
}

export function updateUser(id: string = "", data: any = {}) {
  return postUrl("/user/update?id=" + id, data);
}

export function removeUser(id: string = "") {
  return deleteUrl("/user/remove?id=" + id);
}
