import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllPolicies(max: string = "10") {
  return getUrl(`/contract/all?max=${max}`);
}

export function updatePolicy(id: string = "", data: any = {}) {
  return postUrl("/contract/update?id=" + id, data);
}

export function removePolicy(id: string = "") {
  return deleteUrl("/contract/remove?id=" + id);
}
