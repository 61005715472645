import React from "react";
import ModalCustom from "../..";
import { icons } from "lucide-react";
import Icon from "../../../convenience-icons/icon";
import { useConveniencesStore } from "../../../../configs/stores/conveniences";
import { primaryColor } from "../../../../constants";
import clsx from "clsx";
import Form from "./form";

type Props = {
  handleClose: () => void;
  open: boolean;
  closeOnFinsh?: boolean;
};

const ConvenienceAddModal = ({
  handleClose = () => {},
  open = false,
  closeOnFinsh = true,
}: Props) => {
  const iconNames = Object.keys(icons);
  const [closable] = React.useState(true);
  const [limitList, setlimitList] = React.useState(50);
  const inputTitleRef = React.useRef<HTMLTextAreaElement | any>();
  const [inputText, setinputText] = React.useState("");
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col p-1 pb-2 px-2 pt-0 space-y-4 items-center justify-evenly">
        <span className="flex text-[20px] font-medium">
          Ajouter de nouvelles Comodités
        </span>
        <div className="flex flex-row max-h-[65vh] gap-2 w-[750px] max-w-[65vw]">
          <div className="flex flex-1 flex-col gap-4">
            <input
              ref={inputTitleRef}
              type="text"
              placeholder={"Rechercher"}
              onChange={(e) => {
                setinputText(e.target.value ?? "");
              }}
              className={`flex transition-all duration-150 rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary ${"border-slate-300 outline-none focus:border-primary"} font-medium text-[19px] py-3 bg-slate-100`}
            />
            <div className="flex flex-1 flex-col overflow-y-scroll gap-2">
              <div className="flex flex-row flex-wrap px-5 pl-2 gap-4 justify-evenly">
                {iconNames
                  .filter((e) => {
                    if (!inputText) return true;
                    return e.toLowerCase().includes(inputText);
                  })
                  .slice(0, limitList)
                  .map((iconName, index) => {
                    return (
                      <ModalIcon key={index} name={iconName} color="red" />
                    );
                  })}
              </div>
              {!inputText && iconNames.length > limitList && (
                <span
                  onClick={() => {
                    setlimitList(limitList + 50);
                  }}
                  className="flex cursor-pointer text-primary w-fit ml-3 my-4 hover:underline underline-offset-2"
                >
                  Afficher plus
                </span>
              )}
            </div>
          </div>
          <Form />
        </div>
      </div>
    </ModalCustom>
  );
};

type ModalIconProps = {
  name: string;
  color: string;
};

function ModalIcon({ name }: ModalIconProps) {
  const { conveniences, updateModalSelection } = useConveniencesStore();
  const selectedInModal = useConveniencesStore((e) => e.selectedInModal);
  const exist = conveniences.find((e) => e.convenience_name === name);
  const selected = selectedInModal === name;
  const actived = exist?.convenience_active;
  function onClick() {
    if (!exist) {
      if (!selected) {
        updateModalSelection(name);
      } else {
        updateModalSelection("");
      }
    }
  }
  return (
    <div
      onClick={onClick}
      className={clsx(
        "flex cursor-pointer p-4 h-fit w-fit rounded-lg",
        selected
          ? "bg-primary stroke-white"
          : "bg-slate-500/20 hover:bg-slate-500/30 stroke-black"
      )}
    >
      <Icon
        name={name}
        color={exist ? (actived ? primaryColor : "gray") : undefined}
        size={25}
      />
    </div>
  );
}

export default ConvenienceAddModal;
