import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import {
  getMemberData,
  login,
  logout,
  signup,
  updateCompany,
  updateMember,
} from "./functions/auth";
import { ZustandLocalStorageCrypted } from "./utils";
import { MemberModel } from "../../models/models";
import { ToastErrorNotifier } from "../../constants";

const LOCAL_DATASTORE_NAME = "lesvoyageursadmin-auth";

type ContextProps = {
  tokens: string | null;
  isAuthenticated: boolean;
  isLoadingLogin: boolean;
  isLoadingSignUp: boolean;
  isLoadingLogout: boolean;
  member: MemberModel | null;
  isRefreshingMember: boolean;
  login: (credentials: { login: string; password: string }) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  signup: (credentials: any) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  logout: (cb?: () => void) => Promise<void>;
  refetchMember: () => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  refreshToken: (tokens: string) => Promise<any>;
  updateMember: (update: any) => Promise<any>;
  updateCompany: (update: any) => Promise<any>;
};

export const useAuthStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      tokens: null,
      isAuthenticated: false,
      isLoadingLogin: false,
      isLoadingSignUp: false,
      isLoadingLogout: false,
      member: null,
      isRefreshingMember: false,
      refreshToken: async (tokens: string) => {
        set(
          produce((state: ContextProps) => {
            state.tokens = tokens;
          })
        );
      },
      login: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = true;
          })
        );
        const res = await login(credentials);
        const { success = false, data = null } = res;
        set(
          produce((state: ContextProps) => {
            if (success) {
              state.member = data;
              state.tokens = res.tokens;
            }
          })
        );
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = false;
            if (success) {
              // ToastSuccessNotifier({ message: "Connexion reussie !" });
              state.isAuthenticated = true;
            } else {
              state.isAuthenticated = false;
              // ToastErrorNotifier({
              //   message: message.toString(),
              //   position: "top-center",
              // });
            }
          })
        );
        return res;
      },
      signup: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingSignUp = true;
          })
        );
        const res = await signup(credentials);
        const { success = false, data = null } = res;
        set(
          produce((state: ContextProps) => {
            if (success) {
              state.member = data;
              state.tokens = res.tokens;
            }
          })
        );
        set(
          produce((state: ContextProps) => {
            state.isLoadingSignUp = false;
            if (success) {
              // ToastSuccessNotifier({ message: "Inscription reussie !" });
              state.isAuthenticated = true;
            } else {
              state.isAuthenticated = false;
              // ToastErrorNotifier({
              //   message: message.toString(),
              //   position: "top-center",
              // });
            }
          })
        );
        return res;
      },
      logout: async (cb) => {
        const state = get();
        if (state.isLoadingLogout) {
          return;
        }
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogout = true;
          })
        );
        await logout();
        set(
          produce((state: ContextProps) => {
            state.tokens = null;
            state.isLoadingLogout = false;
            state.isAuthenticated = false;
            state.member = null;
          })
        );
        !!cb && cb();
      },
      refetchMember: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefreshingMember = true;
          })
        );
        const res = await getMemberData();
        const { success = false, data = null } = res;
        set(
          produce((state: ContextProps) => {
            state.isRefreshingMember = false;
            if (success) {
              state.member = data;
              if (!state.isAuthenticated) {
                state.isAuthenticated = true;
              }
            } else {
              state.isAuthenticated = false;
              // ToastErrorNotifier({
              //   message: message.toString(),
              //   position: "top-center",
              // });
              state.member = null;
            }
          })
        );
        return res;
      },
      updateMember: async (update) => {
        const state = get();
        const res = await updateMember(update);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.member = res.data;
            })
          );
          await state.refetchMember()
        } else {
          ToastErrorNotifier({
            message: res.message.toString(),
          });
        }
        return res;
      },
      updateCompany: async (update) => {
        const state = get();
        const res = await updateCompany(update);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.member = res.data;
            })
          );
          await state.refetchMember()
        } else {
          ToastErrorNotifier({
            message: res.message.toString(),
          });
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ath")),
    }
  )
);
