import React from "react";
import { useBasicsStore } from "../../configs/stores/basics";
import { useOneEffect } from "../../hooks/useOnEffect";

const BasicsProvider = ({ children = <div /> }) => {
  const isLoading = useBasicsStore((e) => e.isLoading);
  const getAll = useBasicsStore((e) => e.getAll);
  useOneEffect(() => {
    if (!isLoading) {
      getAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <>{children}</>
    </React.Fragment>
  );
};

export default BasicsProvider;
