import React from "react";
import TableDataAutoList from "../../../../components/array";
import "moment/locale/fr";
import { AdminRoles, useAdminsStore } from "../../../../configs/stores/admins";

const AdminsTable = () => {
  const { admins } = useAdminsStore();
  const columns = [
    {
      label: "Administrateur",
      slug: "name",
      maxWidth: "200px",
    },
    {
      label: "Role",
      slug: "role",
      maxWidth: "200px",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        rows={admins.map(function (admin) {
          return {
            admin,
            name: admin.admin_fullname,
            role:
              AdminRoles.find((e) => e.value === admin.admin_role)?.label ??
              "Inconnue",
          };
        })}
      />
    </div>
  );
};

export default AdminsTable;
