import React from "react";

const CompanyIcon = ({ color = "", ...args }) => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...args}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.89793 3.87179C2.89793 2.25743 4.20663 0.94873 5.82099 0.94873H15.5645C17.1789 0.94873 18.4876 2.25743 18.4876 3.87179V18.4871L19.4619 18.4871C20.0001 18.4871 20.4363 18.9233 20.4363 19.4614C20.4363 19.9996 20.0001 20.4358 19.462 20.4358H1.92356C1.38544 20.4358 0.949212 19.9996 0.949219 19.4614C0.949226 18.9233 1.38547 18.4871 1.92359 18.4871L2.89793 18.4871V3.87179ZM4.84664 18.4871H7.7697V14.5897C7.7697 13.5134 8.64217 12.641 9.71841 12.641H11.6671C12.7434 12.641 13.6158 13.5134 13.6158 14.5897V18.4871H16.5389V3.87179C16.5389 3.33367 16.1027 2.89744 15.5645 2.89744H5.82099C5.28287 2.89744 4.84664 3.33367 4.84664 3.87179V18.4871ZM11.6671 18.4871V14.5897H9.71841V18.4871H11.6671ZM6.79535 5.82048C6.79535 5.28236 7.23158 4.84613 7.7697 4.84613H8.74406C9.28218 4.84613 9.71841 5.28236 9.71841 5.82048C9.71841 6.3586 9.28218 6.79484 8.74406 6.79484H7.7697C7.23158 6.79484 6.79535 6.3586 6.79535 5.82048ZM11.6671 5.82048C11.6671 5.28236 12.1034 4.84613 12.6415 4.84613H13.6158C14.1539 4.84613 14.5902 5.28236 14.5902 5.82048C14.5902 6.3586 14.1539 6.79484 13.6158 6.79484H12.6415C12.1034 6.79484 11.6671 6.3586 11.6671 5.82048ZM6.79535 9.7179C6.79535 9.17978 7.23158 8.74354 7.7697 8.74354H8.74406C9.28218 8.74354 9.71841 9.17978 9.71841 9.7179C9.71841 10.256 9.28218 10.6923 8.74406 10.6923H7.7697C7.23158 10.6923 6.79535 10.256 6.79535 9.7179ZM11.6671 9.7179C11.6671 9.17978 12.1034 8.74354 12.6415 8.74354H13.6158C14.1539 8.74354 14.5902 9.17978 14.5902 9.7179C14.5902 10.256 14.1539 10.6923 13.6158 10.6923H12.6415C12.1034 10.6923 11.6671 10.256 11.6671 9.7179Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default CompanyIcon;
