import React from "react";
import { useTicketStore } from "../../../../configs/stores/ticket";
import TableDataAutoList from "../../../../components/array";

const HomeRecap = () => {
  const { tickets } = useTicketStore();
  const columns = [
    {
      label: "Client",
      slug: "user",
      maxWidth: "170px",
    },
    {
      label: "Compagnie",
      slug: "company",
      maxWidth: "150px",
    },
    {
      label: "Prix de vente (FRCFA)",
      slug: "cost",
      maxWidth: "180px",
    },
    {
      label: "Validé",
      slug: "validate_state",
      maxWidth: "100px",
    },
    {
      label: "Etat",
      slug: "state",
      maxWidth: "100px",
    },
  ];
  return (
    <div className="flex flex-col gap-4">
      <span className="flex text-[17px] font-medium">
        Réservation des 10 derniers tickets en temps réel
      </span>
      <TableDataAutoList
        columns={columns}
        rows={tickets.slice(0, 10).map(function (ticket) {
          return {
            ticket,
            company: ticket.ticket_company_name,
            user: ticket.ticket_user_fullname,
            cost: (ticket?.ticket_cost?.toString() ?? "0"),
            validate_state: ticket.ticket_validate.state ? "Oui" : "Non",
            state: ticket.ticket_payment_state === "paied" ? "Payé" : "Impayé",
          };
        })}
      />
    </div>
  );
};

export default HomeRecap;
