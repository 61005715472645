import React from "react";
import TableDataAutoList from "../../../../components/array";
import "moment/locale/fr";
import { useCompanyStore } from "../../../../configs/stores/companies";

const CompaniesTable = () => {
  const { companies } = useCompanyStore();
  const columns = [
    {
      label: "Company",
      slug: "name",
      maxWidth: "200px",
    },
    {
      label: "Acronyme",
      slug: "acronym",
      maxWidth: "200px",
    },
    {
      label: "Email",
      slug: "email",
      maxWidth: "200px",
    },
    {
      label: "Raison Sociale",
      slug: "reason_social",
      maxWidth: "200px",
    },
    {
      label: "Registre Commerce",
      slug: "commercial_register",
      maxWidth: "200px",
    },
    {
      label: "Compte Contribuant",
      slug: "taxpayer_account",
      maxWidth: "200px",
    },
    {
      label: "Adresse",
      slug: "address",
      maxWidth: "200px",
    },
    {
      label: "Contacts",
      slug: "contacts",
      maxWidth: "200px",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        rows={companies.map(function (company) {
          return {
            company,
            name: company.company_name,
            acronym: company.company_acronym,
            email: company.company_email,
            reason_social: company.company_social_reason,
            commercial_register: company.company_commercial_register_no,
            taxpayer_account: company.company_taxpayer_account_no,
            address: company.company_head_office_address,
            contacts: company.company_contacts.map((e) => e).join(", "),
          };
        })}
      />
    </div>
  );
};

export default CompaniesTable;
