import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  addConvenience,
  getAll,
  removeConvenience,
  updateConvenience,
} from "./functions/convenience";
import { ConvenienceModel } from "../../models/models";

const LOCAL_DATASTORE_NAME = "lesvoyageursadmin-conveniences";

type ContextProps = {
  isLoading: boolean;
  modifs: Array<{ icon: string; update: string }>;
  selected: string;
  selectedInModal: string;
  conveniences: Array<ConvenienceModel>;
  getAll: () => Promise<any>;
  applyModif: (id: string) => Promise<any>;
  addIcon: (icon: string) => void;
  removeIcon: (icon: string) => void;
  clearModifs: () => void;
  removeModif: (icon: string) => void;
  updateSelection: (icon: string) => void;
  updateModalSelection: (icon: string) => void;

  add: (data: any) => Promise<any>;
  update: (id: string, data: any) => Promise<any>;
  remove: (id: string) => Promise<any>;
};

export const useConveniencesStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      conveniences: [],
      selected: "",
      selectedInModal: "",
      modifs: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAll();
        const data = res?.data ?? {};
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.conveniences = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      applyModif: async (id) => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const adds = state.modifs
          .filter((e) => e.update === "add")
          .map((e) => e.icon);
        const removes = state.modifs
          .filter((e) => e.update === "remove")
          .map((e) => e.icon);
        let result: any = null;
        if (adds.length !== 0) {
          // const res = await updateAddIcons(id, adds);
          // result = res.data;
        }
        if (removes.length !== 0) {
          // const res = await updateRemoveIcons(id, removes);
          // result = res.data;
        }
        set(
          produce((state: ContextProps) => {
            state.conveniences = result;
            state.isLoading = false;
          })
        );
        state.clearModifs();
        return null;
      },
      addIcon: (icon) => {
        set(
          produce((state: ContextProps) => {
            state.modifs.concat({
              icon,
              update: "add",
            });
          })
        );
      },
      removeIcon: (icon) => {
        set(
          produce((state: ContextProps) => {
            state.modifs.concat({
              icon,
              update: "remove",
            });
          })
        );
      },
      removeModif: (icon) => {
        set(
          produce((state: ContextProps) => {
            state.modifs = state.modifs.filter((e) => e.icon !== icon);
          })
        );
      },
      clearModifs: () => {
        set(
          produce((state: ContextProps) => {
            state.modifs = [];
          })
        );
      },
      updateSelection: (icon: string) => {
        set(
          produce((state: ContextProps) => {
            state.selected = icon;
          })
        );
      },
      updateModalSelection: (icon: string) => {
        set(
          produce((state: ContextProps) => {
            state.selectedInModal = icon;
          })
        );
      },
      add: async (data) => {
        const state = get();
        const res = await addConvenience(data);
        await state.getAll();
        return res;
      },
      update: async (id, data) => {
        const state = get();
        const res = await updateConvenience(id, data);
        await state.getAll();
        return res;
      },
      remove: async (id) => {
        const state = get();
        const res = await removeConvenience(id);
        await state.getAll();
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
