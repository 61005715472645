import React from "react";
import { useConveniencesStore } from "../../../../configs/stores/conveniences";
import { getPropValue } from "../../../../functions/getPropValue";
import {
  primaryColor,
  ToastErrorNotifier,
  ToastSuccessNotifier,
  ToastWarnNotifier,
} from "../../../../constants";
import Icon from "../../../convenience-icons/icon";

const Form = () => {
  const { selectedInModal, add, updateModalSelection } = useConveniencesStore();
  const inputTitleRef = React.useRef<HTMLInputElement | any>();
  const inputDescriptionRef = React.useRef<HTMLTextAreaElement | any>();
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    convenience_title: "",
    convenience_description: "",
  });
  const [errorState, setErrorState] = React.useState({
    convenience_title: { status: false, msg: "" },
    convenience_description: { status: false, msg: "" },
    generalMsg: "",
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { status: false, msg: "" } }));
      }
    };
  }

  function checkFormData() {
    let errorOccured = false;
    // convenience_title
    if (formData.convenience_title.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        convenience_title: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        convennience_title: { status: false, msg: "" },
      }));
    }
    // convenience_description
    if (formData.convenience_description.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        convenience_description: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        convenience_description: { status: false, msg: "" },
      }));
    }
    return errorOccured;
  }

  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    if (!selectedInModal) {
      ToastWarnNotifier({
        message: "Selectionner l'icone au prealale !",
      });
      return;
    }
    setisLoading(true);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    add({ ...formData, convenience_name: selectedInModal }).then((res) => {
      setisLoading(false);
      if (!res.success) {
        ToastErrorNotifier({
          message: "Echec",
        });
      } else {
        updateModalSelection("");
        inputTitleRef.current.value = "";
        inputDescriptionRef.current.value = "";
        ToastSuccessNotifier({
          message: "Success",
        });
      }
    });
  }
  return (
    <div className="flex flex-col gap-5 w-[47%]">
      <div className="flex flex-col self-center h-[100px] w-[100px] bg-slate-500/20 rounded-lg">
        {!!selectedInModal ? (
          <div className="flex flex-1 items-center justify-center">
            <Icon name={selectedInModal} color={primaryColor} size={60} />
          </div>
        ) : (
          <div className="flex flex-1 items-center text-[14px] justify-center">
            Sélectionner
          </div>
        )}
      </div>
      <input
        ref={inputTitleRef}
        type="text"
        placeholder={"Donner un titre"}
        onChange={(e) =>
          onValueChange("convenience_title")(e.target.value ?? "")
        }
        className={`flex transition-all duration-150 rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary ${
          errorState.convenience_title.status
            ? "caret-red-500 border-red-500 outline-none placeholder:text-red-500"
            : "border-slate-300 outline-none focus:border-primary"
        } font-medium text-[19px] py-3 bg-slate-100`}
      />
      <textarea
        ref={inputDescriptionRef}
        placeholder={"Donner une description"}
        rows={4}
        onChange={(e) =>
          onValueChange("convenience_description")(e.target.value ?? "")
        }
        className={`flex transition-all duration-150 flex-1 max-h-[50%] rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary ${
          errorState.convenience_description.status
            ? "caret-red-500 border-red-500 outline-none placeholder:text-red-500"
            : "border-slate-300 outline-none focus:border-primary"
        } font-medium text-[19px] py-3 bg-slate-100`}
      />
      <button
        onClick={!isLoading ? submitForm : undefined}
        className={`flex py-[8px] transition-all duration-150 bg-primary hover:bg-hover active:bg-active text-[20px] font-semibold text-white rounded-md items-center justify-center`}
      >
        <span>{!isLoading ? "Ajouter" : "Chargement..."}</span>
      </button>
    </div>
  );
};

export default Form;
