import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import LoginForm from "./login-form";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

const SignIn = () => {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const navigate = useNaviLoadBoth;
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <div className="flex flex-1 flex-col gap-10 items-center justify-center overflow-y-auto py-5">
      <div className="flex bg-white rounded-[10px] border-black/75 flex-col w-[380px] gap-5 px-8 py-9">
        <div className="flex leading-tight flex-col text-[22px] text-black font-semibold text-center">
          <span>Connectez-vous</span>
          <span>à votre tableau de bord</span>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default SignIn;
