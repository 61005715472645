import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllCompany(max: string = "10") {
  return getUrl(`/company/all?max=${max}`);
}

export function updateCompany(id: string = "", data: any = {}) {
  return postUrl("/company/update?id=" + id, data);
}

export function removeCompany(id: string = "") {
  return deleteUrl("/company/remove?id=" + id);
}
