import React from "react";
import HomeBlocks from "./blocks";
import HomeRecap from "./recap";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useTicketStore } from "../../../../configs/stores/ticket";
import word from "../../../../assets/word.svg";
import { useStatsStore } from "../../../../configs/stores/stats";

const DashboardHome = () => {
  const getAll = useStatsStore((e) => e.getAll);
  const getAllTicket = useTicketStore((e) => e.getAll);
  function refresh() {
    getAllTicket();
    getAll();
  }
  useOneEffect(() => {
    setTimeout(() => {
      refresh();
    }, 3000);
  }, []);
  return (
    <div className="flex flex-1 flex-col gap-10">
      <span className="flex font-medium text-[22px] select-none">
        Bienvenue sur le Tableau de bord
      </span>
      <HomeBlocks />
      <div className="flex pb-10 flex-row flex-wrap gap-10">
        <HomeRecap />
        <div className="flex flex-1 pt-10 min-w-[400px] max-w-[600px]">
          <img className="w-full" alt="" src={word} />
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
