import React from "react";
import RefreshButton from "../../../../components/buttons/refeshButton";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { usePoliciesStore } from "../../../../configs/stores/policies";
import PoliciesTable from "./table";
import PolicyNoTable from "./table_no_policy";

const PoliciesPage = () => {
  const isLoading = usePoliciesStore((e) => e.isLoading);
  const getAll = usePoliciesStore((e) => e.getAll);
  function refresh() {
    getAll();
  }
  useOneEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        refresh();
      }, 3000);
    }
  }, []);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-10 pt-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">
            Liste des Contrats
          </span>
          <div className="flex flex-row gap-4">
            <RefreshButton refresh={refresh} isLoading={isLoading} />
          </div>
        </div>
        <div className="flex flex-row gap-10">
          <PoliciesTable />
          <div className="flex flex-col gap-5">
            <span className="flex text-[18px] font-medium">
              Contrat en attentent de validation...
            </span>
            <PolicyNoTable />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PoliciesPage;
