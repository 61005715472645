import React from "react";

const HelpIcon = ({ color = "", ...args }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...args}
      >
        <path
          d="M9.24444 0H10.7556C10.853 0.030227 10.9522 0.0548641 11.0524 0.0737909C15.1076 0.607219 17.8969 2.7925 19.3724 6.5905C19.6996 7.42976 19.7973 8.35704 20 9.24609V10.7575C19.9679 10.869 19.9426 10.9824 19.9244 11.0971C19.5333 15.0267 16.6996 18.4628 12.9138 19.5555C12.2098 19.7591 11.4756 19.8569 10.7556 20H9.24444C9.14668 19.9691 9.04688 19.945 8.94578 19.928C6.97723 19.7352 5.11207 18.955 3.59233 17.6888C2.07258 16.4226 0.968414 14.7288 0.423111 12.8272C0.228444 12.1533 0.138667 11.4483 0 10.7575V9.24609C0.0257778 9.13318 0.0595556 9.02205 0.0755556 8.90825C0.644444 4.75729 2.90756 1.9559 6.83467 0.533428C7.60178 0.256935 8.43911 0.177809 9.24444 0ZM18.3404 10.0222C18.328 5.39918 14.6293 1.66518 10.0453 1.66518C5.43111 1.66518 1.656 5.38407 1.65778 9.93777C1.65778 14.5813 5.39111 18.3535 9.95911 18.341C14.6098 18.3277 18.352 14.6133 18.3404 10.0222Z"
          fill={color}
        />
        <path
          d="M11.0773 13.213H9.08356C8.99467 12.2448 9.21955 11.4234 9.93511 10.7468C10.4332 10.2844 10.9028 9.7921 11.3413 9.27276C11.5741 9.00184 11.7396 8.67979 11.8244 8.33281C11.9092 7.98582 11.9109 7.62372 11.8293 7.27596C11.6622 6.4856 11.0622 6.0393 10.1707 6.05352C9.29244 6.06775 8.74844 6.49804 8.59733 7.30441C8.58311 7.37464 8.56444 7.44488 8.544 7.53023H6.65778C6.58133 6.68563 6.80622 5.96551 7.39289 5.37607C8.61067 4.15452 11.2462 3.9776 12.6258 5.01511C13.242 5.47507 13.6789 6.13536 13.8612 6.8825C14.0435 7.62964 13.96 8.41697 13.6249 9.10917C13.3049 9.74135 12.8847 10.3175 12.3804 10.8153C11.6969 11.5025 10.8853 12.0724 11.0773 13.213Z"
          fill={color}
        />
        <path
          d="M9.07644 16.5861V14.7013H11.0844V16.5861H9.07644Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default HelpIcon;
