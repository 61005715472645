import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { getAllStats } from "./functions/stats";

const LOCAL_DATASTORE_NAME = "lesvoyageursadmin-stats";

type ContextProps = {
  isLoading: boolean;
  stats: {
    dashboard: {
      sell_day_length: string;
      sell_day: string;
      companies_length: string;
      users_length: string;
    };
  };
  getAll: () => Promise<any>;
};

export const useStatsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      stats: {
        dashboard: {
          sell_day_length: "0",
          sell_day: "0",
          companies_length: "0",
          users_length: "0",
        },
      },
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllStats();
        const data = res?.data ?? {};
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.stats = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
