import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllTicket(max: string = "10") {
  return getUrl(`/ticket/all?max=${max}`);
}

export function updateTicket(id: string = "", data: any = {}) {
  return postUrl("/ticket/update?id=" + id, data);
}

export function removeTicket(id: string = "") {
  return deleteUrl("/ticket/remove?id=" + id);
}
