import React from "react";
import { primaryColor } from "../../../../constants";
import clsx from "clsx";
import { useStatsStore } from "../../../../configs/stores/stats";

const HomeBlocks = () => {
  const stats = useStatsStore((e) => e.stats);
  return (
    <div className="flex flex-row flex-wrap gap-4">
      <HomeBlockItem
        item={{
          color: "white",
          title: "Réservation du jour",
          amount: stats.dashboard.sell_day_length,
          textButton: "Cumul",
        }}
      />
      <HomeBlockItem
        item={{
          color: "white",
          title: "Vente du jours en FCFA",
          amount: stats.dashboard.sell_day,
          textButton: "Cumul",
        }}
      />
      <HomeBlockItem
        item={{
          color: "white",
          title: "Compagnies inscrites",
          amount: stats.dashboard.companies_length,
          textButton: "Cumul",
        }}
      />
      <HomeBlockItem
        item={{
          color: "white",
          title: "Revenus encaissés en FCFA",
          amount: "0", //"155 322 000",
          textButton: "Cumul",
        }}
      />
      <HomeBlockItem
        item={{
          color: "white",
          title: "Client inscrits à ce jour",
          amount: stats.dashboard.users_length,
          textButton: "Cumul",
        }}
      />
      <HomeBlockItem
        item={{
          color: "white",
          title: "Téléchargement de l’application mobile",
          amount: "0", //"1 000 000",
          textButton: "Cumul",
        }}
      />
      <HomeBlockItem
        item={{
          color: "white",
          textButtonColor: primaryColor,
          title: "Contrat de collaboration en attente",
          amount: "0", //"50",
          textButton: "Cumul",
        }}
      />
      <HomeBlockItem
        item={{
          color: primaryColor,
          textColor: "white",
          title: "Profits générés en FCFA",
          amount: "0", //"6 322 000",
        }}
      />
    </div>
  );
};

type HomeBlockItemProps = {
  item: {
    color: string;
    title: String;
    amount: string;
    textButton?: string;
    textButtonColor?: string;
    textColor?: string;
  };
};
const HomeBlockItem = ({ item }: HomeBlockItemProps) => {
  return (
    <div
      style={{
        color: item.textColor ?? "black",
        backgroundColor: item.color !== "white" ? item.color : undefined,
      }}
      className={clsx(
        `flex flex-col w-[245px] h-[130px] justify-between border border-black/5 shadow-sm`,
        ` p-4 px-5 rounded-lg transition-all duration-150 cursor-pointer`,
        item.color === "white"
          ? `hover:bg-slate-100 active:bg-slate-200 bg-white`
          : ""
      )}
    >
      <span className="flex text-[17px] font-semibold">{item.title}</span>
      <div className="flex flex-row w-full items-center justify-end">
        {!!item?.textButton && (
          <span
            className="flex text-[15px]"
            style={{
              color: item.textButtonColor ?? primaryColor,
            }}
          >
            {item.textButton}
          </span>
        )}
        <span className="flex ml-auto text-[17px] font-semibold">
          {item.amount}
        </span>
      </div>
    </div>
  );
};

export default HomeBlocks;
