import React from "react";
import { useConveniencesStore } from "../../../../configs/stores/conveniences";
import Icon from "../../../../components/convenience-icons/icon";
import { primaryColor } from "../../../../constants";
import { ConvenienceModel } from "../../../../models/models";

const ConvenienceList = () => {
  const conveniences = useConveniencesStore((e) => e.conveniences);
  const isLoading = useConveniencesStore((e) => e.isLoading);
  return (
    <div className="flex flex-col gap-3 border-[4px] border-slate-400/30 rounded-xl bg-slate-400/10 p-5">
      {conveniences.map((e, index) => {
        return (
          <ConvenienceItem
            key={index}
            item={e}
            name={e.convenience_name}
            color="red"
          />
        );
      })}
      {conveniences.length === 0 && (
        <span className="flex text-[15px]">
          {isLoading
            ? "Chargement des Comodités..."
            : "Aucune Comodités Ajouter !"}
        </span>
      )}
    </div>
  );
};

type ConvenienceItemProps = {
  name: string;
  color: string;
  item: ConvenienceModel;
};

function ConvenienceItem({ name, item }: ConvenienceItemProps) {
  const { conveniences, update, remove } = useConveniencesStore();
  const exist = !!conveniences.find((e) => e.convenience_name === name);
  const [isUpdating, setisUpdating] = React.useState(false);
  const [isRemoving, setisRemoving] = React.useState(false);
  function Update(maj: any) {
    setisUpdating(true);
    update(item.convenience_id, maj).then((res) => {
      setisUpdating(false);
    });
  }
  function Remove() {
    setisRemoving(true);
    remove(item.convenience_id).then((res) => {
      setisRemoving(false);
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-row gap-3 items-start">
        <Icon
          name={name}
          color={
            exist
              ? item.convenience_active
                ? primaryColor
                : "gray"
              : undefined
          }
          size={40}
        />
        <div className="flex flex-col gap-[2px]">
          <span className="flex text-[18px] font-semibold">
            {item.convenience_title}
          </span>
          <span className="flex text-[16px] font-medium">
            {item.convenience_description}
          </span>
          <div className="flex flex-row gap-3 text-[13px] font-medium text-primary italic">
            <span
              onClick={() => {
                Update({
                  convenience_active: !item.convenience_active,
                });
              }}
              className="flex cursor-pointer"
            >
              {item.convenience_active
                ? isUpdating
                  ? "Désactivation..."
                  : "Désactiver"
                : isUpdating
                ? "Activation..."
                : "Activer"}
            </span>
            <span>|</span>
            <span onClick={Remove} className="flex cursor-pointer">
              {isRemoving ? "Suppression" : "Supprimer"}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConvenienceList;
