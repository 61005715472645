import React from "react";
import TableDataAutoList from "../../../../components/array";
import { useTicketStore } from "../../../../configs/stores/ticket";
import "moment/locale/fr";
import { TicketModel } from "../../../../models/models";
import TicketActions from "./actions";

const TicketsTable = () => {
  const { tickets } = useTicketStore();
  const columns = [
    {
      label: "Client",
      slug: "user",
      maxWidth: "200px",
    },
    {
      label: "Compagnie",
      slug: "company",
      maxWidth: "200px",
    },
    {
      label: "Prix de vente (FRCFA)",
      slug: "cost",
      maxWidth: "200px",
    },
    {
      label: "Validé",
      slug: "validate_state",
      maxWidth: "80px",
    },
    {
      label: "Etat",
      slug: "state",
      maxWidth: "80px",
    },
    {
      label: "Detail",
      slug: "detail",
      maxWidth: "60px",
      isComponent: true,
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        actions={TicketActions}
        rows={tickets.map(function (ticket) {
          return {
            ticket,
            company: ticket.ticket_company_name,
            user: ticket.ticket_user_fullname,
            cost: ticket?.ticket_cost?.toString() ?? "0",
            validate_state: ticket.ticket_validate.state ? "Oui" : "Non",
            state: ticket.ticket_payment_state === "paied" ? "Payé" : "Impayé",
            detail: <ShowTicketDetail ticket={ticket} />,
          };
        })}
      />
    </div>
  );
};

function ShowTicketDetail({ ticket }: { ticket: TicketModel }) {
  return (
    <div className="flex">
      <span className="flex text-primary cursor-pointer">Afficher</span>
    </div>
  );
}

export default TicketsTable;
