import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllAdmin() {
  return getUrl("/all");
}

export function newAdmin(data: any = {}) {
  return postUrl("/new", data);
}

export function updateAdmin(id: string = "", data: any = {}) {
  return postUrl("/update?id=" + id, data);
}

export function updateAdminPassword(id: string = "", data: any = {}) {
  return postUrl("/update/password?id=" + id, data);
}

export function removeAdmin(id: string = "") {
  return deleteUrl("/remove?id=" + id);
}
